import React from "react"
import styled from "styled-components"
import SEO from "components/seo"
import { GlobalWrapper } from "shared/Styles/Wrappers"
import { OfferCard } from "sections/Offer/OfferCard"
import { PageHeader } from "components/PageHeader"

const StyledGlobalWrapper = styled(GlobalWrapper)`
  background: ${({ theme }) => theme.colors.lightGrayBackground};
`

const Offer = () => (
  <>
    <SEO
      title={"Usługi"}
      description={
        "Fundacja Świat Bez Barier prowadzi również działalność gospodarczą, która pozwala na realizowanie celów statutowych."
      }
    />
    <StyledGlobalWrapper>
      <PageHeader>Usługi</PageHeader>
      <OfferCard />
    </StyledGlobalWrapper>
  </>
)

export default Offer
