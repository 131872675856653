import styled from "styled-components"
import { SectionWrapper } from "shared/Styles/Wrappers"

export const Container = styled.section``

export const Wrapper = styled(SectionWrapper)`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 64px;
  margin: 64px auto;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin: 48px auto;
  }
`

export const Card = styled.div`
  width: calc(50% - 12px);
  display: flex;
  margin-bottom: 24px;
  &:not(:nth-child(2n)) {
    margin-right: 24px;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    width: 100%;
    margin-right: 0 !important;
  }
`

export const TextBox = styled.div`
  background-color: #fff;
`

export const CardBody = styled.div`
  background: ${({ theme }) => theme.colors.white};
  padding: 45px 42px;
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 24px;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-bottom: 15px;
  }
`

export const IconWrapper = styled.div`
  width: 42px;
  height: 42px;
  flex-shrink: 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 34px;
    height: 34px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    width: 24px;
    height: 24px;
  }
`

export const Icon = styled.img`
  max-width: 100%;
  max-height: 100%;
`

export const CardHeader = styled.h2`
  font-weight: bold;
  font-size: 22px;
  margin-left: 32px;
  color: ${({ theme, color }) => (color ? color : theme.colors.fontPrimary)};
  margin-bottom: 8px;
  text-align: left;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 18px;
    margin-left: 30px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 14px;
    margin-left: 24px;
  }
`

export const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 48px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 24px;
  }
`

export const FormWrapper = styled.div``

export const TextWrapper = styled.div`
  max-width: 540px;
`