import React, { useRef, useState } from "react"
import { FormContainer } from "components/Form"
import { Paragraph } from "components/Typography"
import { data } from "./offerData"
import { PageSubHeader } from "components/PageSubHeader/pageSubHeader"
import { PrimaryButton } from "components/Buttons/buttons"
import {
  ButtonWrapper,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormWrapper,
  HeaderWrapper,
  Icon,
  IconWrapper,
  TextBox,
  Wrapper,
  TextWrapper,
} from "./styles"

const OfferCard = () => {
  const [subject, setSubject] = useState(null)
  const formRef = useRef(null)

  const handleClick = subject => {
    setSubject(subject)
    formRef.current.scrollIntoView({ top: 80, behavior: "smooth" })
  }

  return (
    <Container>
      <TextBox>
        <PageSubHeader title="Nasze usługi">
          Fundacja Świat Bez Barier prowadzi również działalność gospodarczą, która pozwala na realizowanie celów statutowych. Dzięki współpracy ze specjalistami z wieloletnim doświadczeniem usługi są kompleksowe i na wysokim poziomie. Zachęcają także podopiecznych Fundacji do rozwijania pasji i dają możliwość nabycia nowych umiejętności.
        </PageSubHeader>
      </TextBox>
      <Wrapper>
        {data.map((el, i) => (
          <Card key={i}>
            <CardBody>
              <TextWrapper>
              <HeaderWrapper>
                <IconWrapper>
                  <Icon src={el.icon} alt="icon" />
                </IconWrapper>
                <CardHeader color="#000">{el.header}</CardHeader>
              </HeaderWrapper>
              <TextWrapper>
                <Paragraph color="000">{el.desc}</Paragraph>
              </TextWrapper>
              <ButtonWrapper>
                <PrimaryButton
                  className="right_arrow"
                  onClick={() => handleClick(el.header)}
                >
                  Zadaj pytanie
                </PrimaryButton>
              </ButtonWrapper>
              </TextWrapper>
            </CardBody>
          </Card>
        ))}
      </Wrapper>
      <FormWrapper ref={formRef}>
        <FormContainer subject={subject} />
      </FormWrapper>
    </Container>
  )
}

export { OfferCard }
