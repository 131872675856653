import ContentIcon from "assets/img/Offer/content.svg"
import DesignAppIcon from "assets/img/Offer/design-app.svg"
import DesignIcon from "assets/img/Offer/design.svg"
import GoogleIcon from "assets/img/Offer/google.svg"
import HatIcon from "assets/img/Offer/hat.svg"
import MarketIcon from "assets/img/Offer/market.svg"
import OfflineIcon from "assets/img/Offer/offline.svg"
import PeopleIcon from "assets/img/Offer/people.svg"
import PhotoIcon from "assets/img/Offer/photo.svg"
import SocialIcon from "assets/img/Offer/social.svg"

export const data = [
  {
    icon: HatIcon,
    header: "Szkolenia i kursy",
    desc:
    "Organizujemy spotkania, które pomagają w rozwoju i poszerzają kompetencje. Chętnie dzielimy się naszą wiedzą i doświadczeniem.",
  },
  {
    icon: PeopleIcon,
    header: "Aktywacja zawodowa i promocja zatrudnienia",
    desc:
    "Pomagamy znaleźć wymarzoną pracę, która spełnia oczekiwania naszych podopiecznych i pozwala rozwijać ich zainteresowania.",
  },
  {
    icon: SocialIcon,
    header: "Social media marketing",
    desc:
    "Prowadzimy działania marketingowe w mediach społecznościowych, które pozytywnie kreują wizerunek marki. Dbamy również o relacje z jej odbiorcami.",
  },
  {
    icon: GoogleIcon,
    header: "Google Ads i Pozycjonowanie",
    desc:
    "Wiemy, jak działają wyszukiwarki internetowe. Dlatego pozycjonowane przez nas strony www zajmują wysoką pozycję w sieci.",
  },
  {
    icon: DesignIcon,
    header:
      "Projektowanie elementów systemu identyfikacji wizualnej oraz projektów graficznych",
    desc:
    "Projektujemy logo, dobieramy odpowiednią kolorystykę, wykonujemy grafiki - wszystko, co składa się na tożsamość marki i podkreśla jej wyjątkowość. ",
  },
  {
    icon: OfflineIcon,
    header: "Działania offline",
    desc:
    "Naszym celem jest dotarcie do szerokiego grona odbiorców w każdym miejscu. Wykonujemy reklamę zewnętrzną, dzięki której jest to możliwe. ",
  },
  {
    icon: DesignAppIcon,
    header: "Projektowanie i wykonywanie serwisów internetowych i aplikacji",
    desc:
    "Tworzymy profesjonalne i innowacyjne strony www oraz aplikacje, które szczegółowo prezentują markę i wyróżniają ją na tle konkurencji. ",
  },
  {
    icon: PhotoIcon,
    header: "Sesje zdjęciowe i filmy korporacyjne ",
    desc:
    "Wykonujemy sesje fotograficzne oraz tworzymy filmy, które eksponują zalety marki i jej mocne strony, a tym samym - zachęcają do współpracy.",
  },
  {
    icon: ContentIcon,
    header: "Content marketing",
    desc:
    "Kreujemy atrakcyjne i unikalne treści, które przyciągają uwagę odbiorców, budują zaangażowanie oraz wzbudzają zainteresowanie.",
  },
  {
    icon: MarketIcon,
    header: "Badanie rynku",
    desc:
    "Przeprowadzamy audyty i badania rynkowe, w których analizujemy obecne działania marki oraz na ich podstawie proponujemy nowe rozwiązania.",
  },
]
